import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './homeAboutService.css';
import AboutService1 from '../../../images/assets/about-service-customer-1.png';
import AboutService2 from '../../../images/assets/customer-person.jpeg';
import AboutService3 from '../../../images/assets/about-service-3.png';

const HomeAboutService = () => (
  <Container className="customer-about-service-container customer" fluid>
    <div className="customer-about-service-bg-wrapper" />
    <img
      className="customer-about-service-image-sp"
      src={AboutService1}
      alt="About Service"
    />
    <div className="label-main">ABOUT SERVICE</div>
    <Row className="customer-about-service-content">
      <Col md={5} xs={12} className="left-content">
        <Row className="col-left-wrapper">
          <Col className="col-left">
            <Row className="label-sub">
              物流業界のプロがあらゆるご要望にお応えいたします。
            </Row>
            <Row className="text-medium">
              物流に関するあらゆるご相談が可能です。
            </Row>
            <Row className="text-normal">
              漠然とした要望・細かい配送指示・荷物の保管・加工などあらゆるご相談が可能です。
            </Row>
            <Row className="text-normal">
              物流業界のプロが丁寧にヒヤリングをするので、細かい指示や配送方法にも対応いたします。
            </Row>
          </Col>
        </Row>
      </Col>
      <Col
        md={7}
        xs={12}
        className="right-content customer-about-service-image-pc ly_pt120"
      >
        <img src={AboutService1} alt="AboutService1" />
      </Col>
    </Row>
    <Row className="customer-about-service-content">
      <Col md={7} xs={12} className="left-content">
        <img src={AboutService2} alt="AboutService2" />
      </Col>
      <Col md={5} xs={12} className="right-content">
        <Row xs={12} className="col-right-wrapper">
          <Col xs={12} md={8} className="col-right">
            <Row className="text-medium">
              WEB申し込みなので24時間365日いつでも、日本全国どこからでもご依頼が可能です。
            </Row>
            <Row className="text-normal">
              ご利用方法は簡単。WEBから会員登録と依頼内容を入力するだけ。
            </Row>
            <Row className="text-normal">
              あとは物流業界のプロからの提案を待つだけなので簡単に利用することができます。
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
    <Row className="customer-about-service-content">
      <img
        src={AboutService3}
        alt="AboutService3"
        className="customer-about-service-image-sp"
      />
      <Col md={5} xs={12} className="left-content">
        <Row className="col-left-wrapper">
          <Col className="col-left">
            <Row className="text-medium mb_0">物流業界のプロが多数在籍。</Row>
            <Row className="text-medium">
              適正な価格・配送方法をご提案します。
            </Row>
            <Row className="text-normal">
              経験豊富な物流業界のプロが多数在籍。
            </Row>
            {/* <Row className="text-normal">
              どう選べばよいかわからない荷物でも、複数社へ相談することができ、適切な価格・適切な配送方法で荷物の見積依頼をすることができます。
            </Row> */}
          </Col>
        </Row>
      </Col>
      <Col
        md={7}
        xs={12}
        className="right-content customer-about-service-image-pc"
      >
        <img src={AboutService3} alt="AboutService3" />
      </Col>
    </Row>
  </Container>
);

export default HomeAboutService;
