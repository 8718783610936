/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import Seo from '../../../components/Seo';
import RowIcon1 from '../../../images/assets/row-icon01.png';
import RowIcon2 from '../../../images/assets/row-icon02.png';
import RowIcon3 from '../../../images/assets/row-icon03.png';
import RowIcon4 from '../../../images/assets/row-icon04.png';
import Feature1 from '../../../images/assets/feature-4.png';
import Feature2 from '../../../images/assets/feature-5.png';
import Feature3 from '../../../images/assets/feature-6.png';
import Footer from '../../../components/Footer';
import HomeBottom from '../../../components/topPage/HomeBottom';
import HomeContact from '../../../components/topPage/HomeContact';
import HomeNews from '../../../components/topPage/HomeNews';
import HomeHeader from '../../../components/topPage/HomeHeader';
import HomeAboutService from '../components/HomeAboutService';
import { getListNotification } from '../../../redux/actions/customer/customer';
import { getMatchingRequest } from '../../../redux/actions/index';

const Home = (props: any) => {
  const dispatch = useDispatch();

  const accessToken = useSelector(
    (state: any) => state.authCustomer.accessToken,
    shallowEqual,
  );
  const notificationList = useSelector(
    (state: any) => state.notifications.notificationTopList,
    shallowEqual,
  );
  const numberMatchingRequest = useSelector(
    (state: any) => state.deliveryRequest.numberMatchingRequest,
    shallowEqual,
  );

  const [data, setData] = useState([]);
  // const data = [
  //   {
  //     name: 'Contactサービス開始のご案内',
  //     date: '2021.09.01',
  //     message:
  //       'Contactをリリースいたしました',
  //     isRead: true,
  //   },
  // ];

  useEffect(() => {
    dispatch(getListNotification(accessToken));
    dispatch(getMatchingRequest());
  }, [accessToken, dispatch]);
  useEffect(() => {
    const notification = notificationList?.map((item) => ({
      message: item.title,
      date: item.posted_date?.substring(0, 10)?.replace(/-/g, '.'),
      id: item.id,
    }));

    setData(notification);
  }, [notificationList]);
  const isLoggedIn = useSelector(
    (state: any) => state.authCustomer.isLoggedIn,
    shallowEqual,
  );
  const onDetail = (id) => {
    navigate(`/notification/${id}`);
  };

  return (
    <>
      <Seo title={props.name} />
      <HomeHeader isCustomerPage isLoggedIn={isLoggedIn} numberMatchingRequest={numberMatchingRequest} />
      <Container className="features-container" fluid>
        <Row className="features-label">
          <Col md={6} xs={12} className="label-main">
            FEATURES
          </Col>
          <Col md={6} xs={12} className="features-content">
            <Row className="label-sub">Contactの特徴</Row>
            <Row className="text-normal me-2">
              Contactは荷主企業様と物流業者様の商談の機会を設けるマッチングサービスです。
            </Row>
            <Row className="text-normal me-2 mb-md-4">
              物流に関する全ての悩みを物流業界のプロが解決いたします。
            </Row>
          </Col>
        </Row>
        <Row className="features-step-row">
          <Col md={3} xs={12} className="features-step">
            <Row className="title-step step-01">配送</Row>
            <hr />
            <Row className="features-image">
              <img src={Feature1} alt="feature-01" />
            </Row>
            <Row className="content-step">
              どんな荷物・どんな大きさでも運んでくれるプロが見つかります。
            </Row>
          </Col>
          <Col md={3} xs={12} className="features-step">
            <Row className="title-step step-02">保管</Row>
            <hr />
            <Row className="features-image">
              <img src={Feature2} alt="feature-02" />
            </Row>
            <Row className="content-step">
              最適な保管方法でご希望の期間荷物を保管してくれるプロが見つかります。
            </Row>
          </Col>
          <Col md={3} xs={12} className="features-step">
            <Row className="title-step step-03">加工</Row>
            <hr />
            <Row className="features-image">
              <img src={Feature3} alt="feature-03" />
            </Row>
            <Row className="content-step">
              商品の加工、検品を行ってくれるプロが見つかります。
            </Row>
          </Col>
        </Row>
      </Container>

      <HomeAboutService />

      <Container
        id="flow"
        className="customer-flow-container flow-container"
        fluid
      >
        <Row className="justify-content-center">
          <Col lg={8} md={9} xs={10} className="flow-body">
            <Row className="flow-row-1">
              <Col lg={5} md={12} xs={12} className="label-main">
                FLOW
              </Col>
              <Col lg={7} md={12} xs={12} className="flow-content">
                <Row className="label-sub">ご利用の流れ</Row>
                <Row className="text-normal">
                  手順は簡単！荷主と物流業者のマッチングが成立したら直接の商談が可能です。
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className="flow-step" lg={3} md={6} xs={12}>
                <Row>
                  <Col xs={5} md={12} className="flow-step-md">
                    <Row className="step-number step-01">STEP</Row>
                    <Row className="step-title">会員登録</Row>
                    <Row className="step-sub-title">ENTRY</Row>
                    <Row className="flow-image">
                      <img src={RowIcon1} alt="flow-01" />
                    </Row>
                  </Col>
                  <Col xs={7} md={12} className="step-description-md">
                    <Row className="step-title-md">会員登録</Row>
                    <Row className="step-description">
                      メールアドレスを入力して簡単会員登録。すぐにサービスをご利用いただくことが可能です。
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col className="flow-step" lg={3} md={6} xs={12}>
                <Row>
                  <Col xs={5} md={12} className="flow-step-md">
                    <Row className="step-number step-02">STEP</Row>
                    <Row className="step-title">見積依頼</Row>
                    <Row className="step-sub-title">REQEST</Row>
                    <Row className="flow-image">
                      <img src={RowIcon2} alt="flow-02" />
                    </Row>
                  </Col>
                  <Col xs={7} md={12} className="step-description-md">
                    <Row className="step-title-md">見積依頼</Row>
                    <Row className="step-description">
                      会員情報の登録、依頼内容、荷物などの項目を入力して物流業者からの見積提案を待ちます。
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col className="flow-step" lg={3} md={6} xs={12}>
                <Row>
                  <Col xs={5} md={12} className="flow-step-md">
                    <Row className="step-number step-03">STEP</Row>
                    <Row className="step-title">業社選択</Row>
                    <Row className="step-sub-title">SELECT</Row>
                    <Row className="flow-image">
                      <img src={RowIcon3} alt="flow-03" />
                    </Row>
                  </Col>
                  <Col xs={7} md={12} className="step-description-md">
                    <Row className="step-title-md">業社選択</Row>
                    <Row className="step-description">
                      見積提案が届いたら相談したい業者を選択し、商談を進めます。（複数の業者様と同時に商談を進めることが可能です。）
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col className="flow-step" lg={3} md={6} xs={12}>
                <Row>
                  <Col xs={5} md={12} className="flow-step-md">
                    <Row className="step-number step-04">STEP</Row>
                    <Row className="step-title">発注</Row>
                    <Row className="step-sub-title">ORDER</Row>
                    <Row className="flow-image">
                      <img src={RowIcon4} alt="flow-04" />
                    </Row>
                  </Col>
                  <Col xs={7} md={12} className="step-description-md">
                    <Row className="step-title-md">発送</Row>
                    <Row className="step-description">
                      直接やりとりをしていただき、正式に業務を依頼してください。
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="price-button">
          <Link to="/customer/price" className="price-link">
            ご利用料金はこちら
          </Link>
        </Row>
      </Container>
      <HomeNews role="customer" listNews={data} onDetail={onDetail} />
      <HomeContact />
      <HomeBottom isCustomerPage />
      <Footer />
    </>
  );
};
export default Home;
